.Autocomplete {
  position: relative;
  vertical-align: top;
  
  // Modifiers
  &--active,
  &--hoverable:hover {
    .Autocomplete__Menu {
      display: block !important;
    }
  }
  &--right {
    .Autocomplete__Menu {
      left: auto;
      right: 0;
    }
  }
  &--up {
    .Autocomplete__Menu {
      bottom: 100%;
      padding-bottom: $dropdownContentOffset;
      padding-top: initial;
      top: auto;
    }
  }
  
  &__Menu {
    display: none;
    left: 0;
    width: 100%;
    padding-top: $dropdownContentOffset;
    position: absolute;
    top: 100%;
    z-index: $dropdownContentZ;
  }
  
  &__Content {
    border-radius: $dropdownContentRadius;
    padding-bottom: $dropdownContentPaddingBottom;
    padding-top: $dropdownContentPaddingTop;
  }

  .Input {
    // padding-left: 1rem !important;
  }

  .Icon {
    width: 1.25rem;
    // right: 1rem !important;
  }
}

.AutocompleteItem {
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  position: relative;
  user-select: none;
}

a.AutocompleteItem {
  cursor: pointer;
  padding-right: 3rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}

.AutocompleteDivider {
  border: none;
  display: block;
  height: 1px;
  margin: 0.5rem 0;
}
