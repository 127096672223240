.Content {
  line-height: 1.4;

  > * {
    &:not(:last-child) {
      margin-bottom: $spacing * 0.5;
    }
  }

  > ul,
  > ol {
    padding-left: 1.25em;

    > li {
      &:not(:last-child) {
        margin-bottom: $spacing * 0.25;
      }
    }
  }

  > ul {
    list-style-type: disc;
  }

  > ol {
    list-style-type: decimal;
  }
}
