.MenuListItem {
  color: $primaryColor;
  transition-property: color, background-color;
  transition-duration: 200ms;
  transition-timing-function: ease;

  &::after {
    transition: all 200ms ease;
    background-color: $menuListHoverBorderColor;
    transform: scale(0, 1);
    transform-origin: 100% 50%;
  }

  @include isDesktop {
    &:hover {
    //   color: $menuListHoverColor;
    //   background-color: $primaryColor;
      transition-duration: 0s;

      &::after {
        transform: scale(1, 1);
      }
    }
  }

  &.--active {

    &::after {
      background-color: $menuListActiveBorderColor;
      transform: scale(1, 1);
    }

    @include isDesktop {
      &:hover {
        &::after {
          background-color: $menuListHoverBorderColor;
          transform: scale(1, 1);
        }
      }
    }
  }
}

.MenuListOverlay {
  background: rgba($theme, 0.9);
  backdrop-filter: blur(4px);
}
