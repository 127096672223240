@keyframes logoShake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.AppHeader {
  background-color: $ioDarkerBlue;

  &__Mobile {
    background-color: $ioDarkerBlue;
    transition: all 1s ease;
    transition-property: background-color, backdrop-filter;
    transition-delay: 100ms;

    &__Logo {
      transition: all 200ms ease;

      &--hidden {
        transform: scale(0.5);
        opacity: 0;
      }
    }
  }

  &__Desktop {
    &__Logo {
      transition: all 200ms ease;

      &:hover {
        animation: logoShake 1s;
        animation-iteration-count: infinite;
      }
    }
  }

  a.--active {
    font-weight: bold;
    color: $fontColor !important;
  }

  .IoSearch {
    .Input {
      background-color: rgba(0, 0, 0, 0.35) !important;
    }
  }

  .Button--text {
	
	margin-top: 0;
	display: block;

    span {
      display: inline-block;
      position: relative;
      z-index: 1;
      text-shadow: 0 1px 1px $darkBgColor;
      transition: all 200ms ease;
    }

    &::before, 
    &::after {
		background: none;
      height: 0;
      z-index: 0;
    }

    @include isDesktop {
      &:hover {
        span {
          transform: translateY(-3px);
          text-shadow: 0 0px 5px rgba($primaryColor, 0.8);
        }
      }
    }
  }
}
